import { AutoComplete } from "components/AutoComplete";
import { CustomModal } from "components/CustomModal";
import { TextInput } from "components/Input";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LeftArrowIcon from "assets/images/customer/svg/LeftArrowIcon";
import { Form, FormGroup, Label } from "reactstrap";
import { customStyles, countriesOptions, shippingActive } from "../../../constants";
import PhoneInput, {
    parsePhoneNumber,
    getCountryCallingCode,
    isValidPhoneNumber
} from "react-phone-number-input";
import { CustomButton } from "components/CustomButton";
import { GetActiveCountries, UpdateDeliveryInfo } from "api/api.service";
import toast from "react-hot-toast";

function TMCRApplicationForm(props) {
    const { isOpen, handleClose, registrationId } = { ...props };
    const [countries, setCountries] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(async () => {
        getActiveCountries();
    }, []);

    // fetching active countries from API
    const getActiveCountries = async () => {
        const res = await GetActiveCountries();
        const validKeys = ["name", "id"];
        res?.data.map((country) => {
            Object.keys(country).forEach((key) => validKeys.includes(key) || delete country[key]);
            delete Object.assign(country, { ["label"]: country["name"] })["name"];
            delete Object.assign(country, { ["countryId"]: country["id"] })["id"];
            country.value = country.label;
            return country;
        });
        setCountries(res?.data);
    };

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            customerName: "",
            phoneNumber: "",
            // country: "",
            address: ""
        },
        validationSchema: Yup.object({
            customerName: Yup.string().required("Enter Your Name"),
            phoneNumber: Yup.string()
                .required("Enter Phone Number")
                .test("phoneNumber", "Phone number is invalid", function (val) {
                    return val && isValidPhoneNumber(val);
                }),
            address: Yup.string().required("Enter Your Address")
        }),
        onSubmit: (values) => {
            handleSubmit(values);
            // resetForm();
        }
    });

    const handleSubmit = async (initialValues) => {
        const { phoneNumber } = initialValues;

        let countryCodeAlpha = parsePhoneNumber(phoneNumber).country;
        let countryCode = getCountryCallingCode(countryCodeAlpha);
        countryCode = `+${countryCode}`;
        let phone = phoneNumber.split(countryCode)[1];

        let formValues = { ...initialValues };

        formValues.phoneNumber = phone;
        formValues.countryCodeAlpha = countryCodeAlpha;
        formValues.countryCode = countryCode;

        try {
            setIsLoading(true);
            let res = await UpdateDeliveryInfo(Number(registrationId), formValues);
            if (res?.message == "Updated") {
                setIsLoading(false);
                toast.success("Delivery information has been updated!");
            }

        } finally {

            setTimeout(() => {
                handleClose();
            }, 1200);
            setIsLoading(false);

        }
    };
    return (
        <CustomModal
            isOpen={isOpen}
            size="lg"
            data={
                <div className="p-4 modal-detail">
                    <div className="d-flex align-items-center">
                        <button className="border-0  p-0  bg-white" onClick={handleClose}>
                            <LeftArrowIcon />
                        </button>

                        <h2 className="m-0 px-2">Get Physical Original TMCR</h2>
                    </div>
                    <div className="p-4 " style={{ paddingLeft: "40px !important" }}>
                        <h6>Request to send original TMCR express mail</h6>
                        <p>
                            if you want to recieve a original TMCR mail.Please fill out the below
                            form.
                        </p>
                        {/* </div>
                        <div> */}
                        <Form
                            onSubmit={formik.handleSubmit}
                            className="py-4 pl-6 trademark-registration-modal "
                        >
                            {/*onClick={formik.handleSubmit}*/}
                            <FormGroup>
                                <Label for="exampleEmail">Full Name</Label>

                                <TextInput
                                    placeholder="John Smith"
                                    name={"customerName"}
                                    type="text"
                                    className="hide-icon w-full"
                                    value={formik.values.customerName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.customerName && formik.errors.customerName ? (
                                    <span className="error">{formik.errors.customerName}</span>
                                ) : null}
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleEmail">Phone</Label>

                                <PhoneInput
                                    defaultCountry={"SA"}
                                    countryOptionsOrder={["SA", "AE", "OM", "BH", "KW", "QA"]}
                                    onChange={(values) =>
                                        formik.setFieldValue("phoneNumber", values)
                                    }
                                    onBlur={() => {
                                        formik.setFieldTouched("phoneNumber");
                                    }}
                                    value={formik.values.phoneNumber}
                                    name="phoneNumber"
                                    placeholder="Phone Number"
                                    className="phone-num-con"
                                />
                                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                    <span className="error">{formik.errors.phoneNumber}</span>
                                ) : null}
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleEmail">Country</Label>
                                {countries && (
                                    <AutoComplete
                                        required
                                        // customStyles={customStyles.addNewDept}
                                        onChange={(val) =>
                                            formik.setFieldValue("countryId", val.countryId)
                                        }
                                        name="countryId"
                                        isSearchable={true}
                                        classNamePrefix="status-header-search-admin w-40"
                                        options={countries}
                                        placeholder="Select Country"
                                    />
                                )}
                                {formik.touched.country && formik.errors.country ? (
                                    <span className="error">{formik.errors.country}</span>
                                ) : null}
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleEmail">Address</Label>

                                <TextInput
                                    placeholder="Address"
                                    name={"address"}
                                    type="text"
                                    className="hide-icon w-full"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.address}
                                />
                                {formik.touched.address && formik.errors.address ? (
                                    <span className="error">{formik.errors.address}</span>
                                ) : null}
                            </FormGroup>
                            <FormGroup>
                                <CustomButton
                                    color="primary"
                                    title="Save Details"
                                    type={"submit"}
                                    loading={isLoading}
                                    className="w-100 bv-btn-sm mb-3"
                                />
                            </FormGroup>
                        </Form>
                    </div>
                </div>
            }
        />
    );
}

export default TMCRApplicationForm;
