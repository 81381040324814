import { TextInput } from "components/Input";
import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { ImageUpload } from "../../../components/ImageUpload";
import { CustomButton } from "components/CustomButton";
import { customStyles, countriesOptions, shippingActive, CardTypes } from "../../../constants";
import { AutoComplete } from "../../../components/AutoComplete";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomModal } from "components/CustomModal";
import LeftArrowIcon from "assets/images/customer/svg/LeftArrowIcon";
import Visa from "assets/images/customer/svg/paymentMathod/Visa";
import American from "assets/images/customer/svg/paymentMathod/American";
import Mada from "assets/images/customer/svg/paymentMathod/Mada";
import CardIcon from "assets/images/customer/svg/CardIcon";
import AlertModal from "../common/AlertModal/AlertModal";
import SuccessIcon from "assets/images/customer/svg/SuccessIcon";
import { useHistory } from "react-router-dom";
import { CUSTOMER_ROUTES } from "helpers/routeHelpers";
import { documentTypePayload, fieldRequirementMessage } from "pages/CaseManagement/Constants";
import { ImageTypes } from "../../../constants";
import { finalizeMediaUtil, initializeMediaUtil, uploadOnS3 } from "../../../utils/mediaUtils";
import { removeSpaceUnderscoreCharacterFromString } from "../../../utils/commonUtils";
import {
    GetAssignedCountries,
    GetSystemFee,
    TrademarkFiling,
    getFirstStepFee
} from "api/api.service";
import TrademarkPaymentModal from "./TrademarkPaymentModal";
import shortLogo from "assets/images/customer/short-logo.png";
import PaymentModal from "./PaymentModal";
import { ConfirmationModal } from "../common/ConfirmationModal";

const KB_CONVERTER = 1024;

const TrademarkSubmissionForm = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPaymentCardSelected, setIsPaymentCardSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [selectedCard, setSelectedCard] = useState(null);
    const [isFillingLoader, setIsFillingLoader] = useState(false);

    const [isPaymentMethodModal, setIsPaymentMethodModal] = useState(false);
    const [isTermConditionModal, setIsTermConditionModal] = useState(false);
    const [countries, setCountries] = useState({});
    const [systemFee, setSystemFee] = useState();
    const [paymentId, setPaymentId] = useState();
    const [isChecked, setIsChecked] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isTrademarkCancelled, setIsTrademarkedCancelled] = useState(false);

    const history = useHistory();

    localStorage.removeItem("trademarkId");
    localStorage.removeItem("TMCR_Id");

    const [trademarkImage, setTrademarkImage] = useState({
        image: "",
        fileName: undefined,
        imageError: undefined
    });
    const [commercialImage, setCommercialImage] = useState({
        image: "",
        fileName: undefined,
        imageError: undefined
    });
    const [errorType, setErrorType] = useState(0);

    useEffect(async () => {
        getActiveCountries();
        getSystemFees();
    }, []);

    // fetching system fee from API
    const getSystemFees = async () => {
        const res = await GetSystemFee();
        setSystemFee(res?.filingAmount);
    };

    // fetching active countries from API
    const getActiveCountries = async () => {
        const res = await GetAssignedCountries();
        const validKeys = ["name", "id"];
        res?.data.map((country) => {
            Object.keys(country).forEach((key) => validKeys.includes(key) || delete country[key]);
            delete Object.assign(country, { ["label"]: country["name"] })["name"];
            delete Object.assign(country, { ["countryId"]: country["id"] })["id"];
            country.value = country.label;
            return country;
        });
        setCountries(res?.data);
    };

    let dataObj = {
        description: "",
        applicantName: "",
        applicantAddress: "",
        goodType: "",
        cardType: ""
    };
    // file uploading
    const FileUploadHander = (file) => {
        let imageError = undefined;
        let fileName = undefined;
        let image = "";
        if (file) {
            const uploadedFileType = file?.name
                ?.split(".")
                [file?.name?.split(".").length - 1]?.toLowerCase();
            let fileSize = file && file?.size;
            fileSize = fileSize / KB_CONVERTER;
            if (!ImageTypes.includes(uploadedFileType)) {
                imageError = responseMessages.FILE_TYPE_ERROR;
                fileName = undefined;
                image = "";
            } else {
                fileName = removeSpaceUnderscoreCharacterFromString(file?.name);
                imageError = "";
                image = file;
                image.fileName = fileName;
            }
        }
        return { imageError, image, fileName };
    };

    const handleFileChange = (e, imageObjUpdater, type) => {
        e.preventDefault();
        let file = e.target.files[0];
        const { image, imageError, fileName } = FileUploadHander(file);
        imageObjUpdater({ image, imageError, fileName });
        if (errorType === 3) {
            type == documentTypePayload.TRADEMARK_IMAGE && !commercialImage.image
                ? setErrorType(2)
                : type == documentTypePayload.TRADEMARK_COMMERCIAL &&
                  !commercialImage.image &&
                  setErrorType(1);
        } else if (errorType === 1) {
            type == documentTypePayload.TRADEMARK_IMAGE && !commercialImage.image
                ? setErrorType(2)
                : type == documentTypePayload.TRADEMARK_IMAGE &&
                  trademarkImage.image &&
                  setErrorType(0);
        } else if (errorType === 2) {
            type == documentTypePayload.TRADEMARK_COMMERCIAL && !commercialImage.image
                ? setErrorType(1)
                : type == documentTypePayload.TRADEMARK_COMMERCIAL &&
                  commercialImage.image &&
                  setErrorType(0);
        }
    };

    // hanlde remove image
    const removeImage = (imageObjUpdater) => {
        imageObjUpdater({ image: "", imageError: undefined, fileName: undefined });
    };

    // media upload on server
    const handleMediaUpload = async (file) => {
        return initializeMediaUtil(file).then(async (res) => {
            const credentials = res;
            await uploadOnS3(file, credentials);
            return await finalizeMediaUtil(credentials?.mediaId);
        });
    };

    // formik initialization
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...dataObj
        },
        validationSchema: Yup.object({
            description: Yup.string().required("Please enter Description"),
            applicantName: Yup.string().required("Please enter your Name"),
            applicantAddress: Yup.string().required("Please enter your Address")
            // countryId: Yup.number().required("Please select a country")
        }),
        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    // data propogation to api for step 1
    const handleFormSubmit = async (values, e) => {
        let payLoadObj = values;
        payLoadObj.title = payLoadObj.description;

        if (trademarkImage.image === "" && commercialImage.image === "") {
            setErrorType(3);
            return;
        } else if (trademarkImage.image === "") {
            setErrorType(1);
            return;
        } else if (commercialImage.image === "") {
            setErrorType(2);
            return;
        } else {
            if (!isChecked) {
                setShowError(true);
            } else {
                setShowError(false);

                setIsLoading(true);
                payLoadObj.documents = [];
                if (trademarkImage.image) {
                    let imageRes = await handleMediaUpload(trademarkImage.image);
                    payLoadObj.documents.push({
                        documentType: documentTypePayload.TRADEMARK_IMAGE,
                        mediaId: imageRes.id
                    });
                }
                if (commercialImage.image) {
                    let imageRes = await handleMediaUpload(commercialImage.image);
                    payLoadObj.documents.push({
                        documentType: documentTypePayload.TRADEMARK_COMMERCIAL,
                        mediaId: imageRes.id
                    });
                }

                try {
                    setFormData(payLoadObj);
                    setIsModalOpen(true);
                } finally {
                    setIsLoading(false);
                }
            }
        }
    };
    const tradeFillingHandler = async () => {
        setIsFillingLoader(true);
        let dataobj = { ...formData, cardType: selectedCard };
        try {
            let res = await TrademarkFiling(dataobj);
            setPaymentId(res?.paymentId);
            setIsPaymentMethodModal(true);
            setIsPaymentCardSelected(false);
        } finally {
            setIsFillingLoader(false);
        }
    };

    const handleCardSelect = (cardType) => {
        setSelectedCard(cardType);
    };

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const handleTermsConditions = () => {
        setIsTermConditionModal(true);
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        if (e.target.checked) {
            setShowError(false); // Hide error if checkbox is checked
        }
    };

    const handleCancel = () => {
        setShowError(false);
        setIsTrademarkedCancelled(false);
    };

    return (
        <React.Fragment>
            <form className="mt-2" onSubmit={formik.handleSubmit}>
                <FormGroup>
                    <h2 className="fw-bold">Description</h2>
                    <p className="fs-5">Description of mark in one to three words</p>
                    <TextInput
                        placeholder="Type Description"
                        name={"description"}
                        type="text"
                        className="hide-icon mt-2 w-50 shadow-none"
                        style={{ borderRadius: "5px" }}
                        value={formik.values.description}
                        inputGroupClassName={`${
                            formik.touched.description &&
                            formik.errors.description &&
                            "border-danger"
                        }`}
                        onChange={formik.handleChange}
                        required
                    />
                    {formik.touched.description && formik.errors.description ? (
                        <span className="error">{formik.errors.description}</span>
                    ) : null}
                </FormGroup>
                <FormGroup>
                    <h2 className="fw-bold">Trademark</h2>
                    <p className="fs-5">
                        This trademark will be examined by our company. If the mark is
                        invalid/illegal, we will inform you to adjust your mark through the chat
                        button
                    </p>
                    <Row>
                        <Col md={3}>
                            <ImageUpload
                                inputId={1}
                                onChange={(e) =>
                                    handleFileChange(
                                        e,
                                        setTrademarkImage,
                                        documentTypePayload.TRADEMARK_IMAGE
                                    )
                                }
                                fileName={trademarkImage?.fileName}
                                imageError={trademarkImage?.imageError}
                                removeImageHandler={() => removeImage(setTrademarkImage)}
                                errorType={
                                    errorType === 1 || errorType === 3
                                        ? fieldRequirementMessage.UPLOAD_FILE
                                        : ""
                                }
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <h2 className="fw-bold">Commercial Registration/Passport</h2>
                    <p className="fs-5">
                        You shall upload a valid Commercial Registration of your company or a valid
                        national passport for individuals.
                    </p>
                    <Row>
                        <Col md={3}>
                            <ImageUpload
                                inputId={2}
                                onChange={(e) =>
                                    handleFileChange(
                                        e,
                                        setCommercialImage,
                                        documentTypePayload.TRADEMARK_COMMERCIAL
                                    )
                                }
                                fileName={commercialImage?.fileName}
                                imageError={commercialImage?.imageError}
                                removeImageHandler={() => removeImage(setCommercialImage)}
                                errorType={
                                    errorType === 1 || errorType === 3
                                        ? fieldRequirementMessage.UPLOAD_FILE
                                        : ""
                                }
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <h2 className="fw-bold">Applicant's Name</h2>
                    <p className="fs-5">
                        This name shall be identical to what is written to the name in the
                        commercial registration for companies or national passport for individuals.
                    </p>
                    <TextInput
                        placeholder="Type Applicant's Name Here"
                        name={"applicantName"}
                        type="text"
                        className="hide-icon mt-2 w-50 shadow-none"
                        style={{ borderRadius: "5px" }}
                        autoFocus
                        inputGroupClassName={`${
                            formik.touched.applicantName &&
                            formik.errors.applicantName &&
                            "border-danger"
                        }`}
                        onChange={formik.handleChange}
                        value={formik.values.applicantName}
                    />
                    {formik.touched.applicantName && formik.errors.applicantName ? (
                        <span className="error">{formik.errors.applicantName}</span>
                    ) : null}
                </FormGroup>
                <FormGroup>
                    <h2 className="fw-bold">Applicant's Address</h2>
                    <p className="fs-5">
                        This address shall be identical to what is written to the address in the
                        commercial registration for companies or national passport for individuals.
                    </p>
                    <TextInput
                        placeholder="Type Address Here"
                        name={"applicantAddress"}
                        type="text"
                        className="hide-icon mt-2 w-50 shadow-none"
                        style={{ borderRadius: "5px" }}
                        autoFocus
                        inputGroupClassName={`${
                            formik.touched.applicantAddress &&
                            formik.errors.applicantAddress &&
                            "border-danger"
                        }`}
                        onChange={formik.handleChange}
                        value={formik.values.applicantAddress}
                    />
                    {formik.touched.applicantAddress && formik.errors.applicantAddress ? (
                        <span className="error">{formik.errors.applicantAddress}</span>
                    ) : null}
                </FormGroup>
                <FormGroup>
                    <h2 className="fw-bold">Country</h2>
                    <p className="fs-5 mb-2">
                        You only can select a single country for each request.
                    </p>
                    <div className="country-select">
                        {countries && (
                            <AutoComplete
                                required
                                // customStyles={customStyles.addNewDept}
                                onChange={(val) => formik.setFieldValue("countryId", val.countryId)}
                                name="countryId"
                                isSearchable={true}
                                classNamePrefix="status-header-search-admin w-40"
                                options={countries}
                                placeholder="Select Country"
                            />
                        )}
                    </div>
                </FormGroup>
                <FormGroup>
                    <h2 className="fw-bold">Types of Goods/Services</h2>
                    <p className="fs-5">You can add one sort of goods/services for each request.</p>
                    <TextInput
                        placeholder="Type goods here"
                        name={"goodType"}
                        type="text"
                        className="hide-icon mt-2 w-50 shadow-none"
                        style={{ borderRadius: "5px" }}
                        autoFocus
                        inputGroupClassName={`${
                            formik.touched.goodType && formik.errors.goodType && "border-danger"
                        }`}
                        onChange={formik.handleChange}
                        value={formik.values.goodType}
                    />
                    {formik.touched.goodType && formik.errors.goodType ? (
                        <span className="error">{formik.errors.goodType}</span>
                    ) : null}
                </FormGroup>
                <FormGroup>
                    {/* <Input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        width={40}
                        height={40}
                        required
                        style={{ width: "20px", height: "20px" }}
                    /> */}
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckChecked"
                        onChange={handleCheckboxChange}
                        style={{ width: "20px", height: "20px" }}
                    />
                    <label
                        class="form-check-label"
                        for="flexCheckChecked"
                        style={{ fontSize: "16px", marginLeft: "10px" }}
                    >
                        I agree to the
                    </label>
                    <button
                        className="bg-transparent border-0 bold "
                        style={{ fontSize: "16px" }}
                        type="button"
                        onClick={handleTermsConditions}
                    >
                        Terms & Conditions
                    </button>
                    {showError && (
                        <div className="mt-2">
                            <span className="error">
                                ⚠️ Please check this box if you want to proceed.
                            </span>
                        </div>
                    )}
                </FormGroup>
                <Row className="btn-row d-flex justify-content-around mt-4 mb-5">
                    <CustomButton
                        type="submit"
                        color="primary"
                        title="Continue"
                        loading={isLoading}
                        // onClick={handleModalOpen}
                        className="w-40 login-btn-typo continue-btn"
                    />
                    <button
                        className="w-40 cancel-btn"
                        onClick={(e) => {
                            e.preventDefault();
                            setIsTrademarkedCancelled(true);
                        }}
                    >
                        Cancel
                    </button>
                </Row>
            </form>
            <CustomModal
                isOpen={isTermConditionModal}
                size={"lg"}
                data={
                    <div className="p-4 modal-detail">
                        <h2 className="text-center">Terms & Conditions</h2>
                        <div className="my-4">
                            <ul>
                                <li>
                                    <b>Duration & Termination: </b>This service STARTS after
                                    receiving the payment of{" "}
                                    <span className="text-primary">STEP 1 </span> and ENDS in either
                                    of the following: (1) Failure on{" "}
                                    <span className="text-primary">STEP 1</span> for uploading a
                                    mark/logo that consists of a religious flag/symbol, or a country
                                    flag, or offends the public values/morals; (2) Receiving
                                    APPLICATION failure report (for any reason) during{" "}
                                    <span className="text-primary">STEPs 1, 2, 3, 4;</span> (3)
                                    Nonpayment on time during{" "}
                                    <span className="text-primary">STEPS 1, 2, 3, 4.</span>
                                </li>
                                <li>
                                    {" "}
                                    <b>Limitation of Liability:</b>{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />
                                    <span className="text-primary"> Web Platform </span>is not
                                    liable for any delay of processing the APPLICATION by the
                                    national trademark office, the delay as a result of war, natural
                                    disaster, or any other force majeure matters.
                                </li>
                                <li>
                                    <b>Acceptance of the Use of Application:</b> By continuing the
                                    use of <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    <span className="text-primary">Web Platform </span> the
                                    applicant explicitly accepts all the terms and conditions
                                    herewith otherwise the applicant should STOP using{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    <span className="text-primary">Web Platform</span> .
                                </li>
                                <li>
                                    <b>Monitoring Notice:</b> The Applicant is required to monitor
                                    his application in a daily basis for any development or any
                                    chatting messages sent from our Team. The User is responsible
                                    for checking the received messages, whether through the
                                    application or the web, because these messages and alerts are
                                    specific to maintaining the continuity of the Application. The
                                    chat box and received alerts must be checked continuously, and
                                    the requirements received must be fulfilled. Any abandonment of
                                    the messages received in the chat box is the
                                    user’s responsibility. Deadlines and important notices will be
                                    sent over the chatting box at every stage.
                                </li>
                                <li>
                                    <b> Failure of Application: </b> Application may fail and not go
                                    through in each of the{" "}
                                    <span className="text-primary">STEPS 1, 2, 3, 4</span> . The
                                    applicant accepts and agrees unconditionally that they will not
                                    request any refund for any payment that has been made before the
                                    APPLICATION's failure.
                                </li>
                                <li>
                                    <b>Limitation of Liability: </b>
                                    <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    <span className="text-primary">Web Platform</span> is operating
                                    this application as per the applicant's instruction and Co
                                    authorization to perform any modification necessary to maintain
                                    the application alive. Therefore, the applicant must confirm
                                    that <img src={shortLogo} alt="logo" width={80} height={18} />
                                    <span className="text-primary">Web Platform</span> is free of
                                    any direct or indirect damages or loss caused by the application
                                    as a result of the following:
                                    <ul>
                                        <li>Failure of the application</li>
                                        <li>Delay of the application</li>
                                        <li>Modification of the application</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Governing Law and Settlement of Disputes:</b> The operation
                                    of this application is totally based on the trust, authority,
                                    and power given by the applicant to{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    <span className="text-primary">Web Platform </span> to do its
                                    best efforts for the registration of the applicant's mark. The
                                    applicant has provided a full limitation of liability to{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />
                                    <span className="text-primary">Web Platform</span>. The
                                    applicant has the right to abandon the application at any stage,
                                    therefore no dispute between the parties could ever occur. Both
                                    applicant and{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} /> agree
                                    on this non-dispute condition and that neither party in any
                                    circumstances will sue the other party, take any legal action,
                                    file any case against them under any applicable law or in any
                                    applicable jurisdiction of any country.
                                </li>
                                <li>
                                    <b>Confidentiality:</b> The information provided by the
                                    applicant is subject to confidentiality and will not be used
                                    except for the purpose of registering the client's mark.
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex justify-content-evenly align-items-center">
                            <CustomButton
                                color="primary"
                                title="Done"
                                className="bv-btn-sm mb-3"
                                onClick={() => {
                                    setIsTermConditionModal(false);
                                }}
                            />
                        </div>
                    </div>
                }
            />
            <CustomModal
                isOpen={isModalOpen}
                data={
                    <div className="p-4 modal-detail">
                        <h2 className="">Important Note</h2>
                        <div className="my-4">
                            <h6 className="text-primary">Payments</h6>
                            <ul>
                                <li>
                                    {" "}
                                    Payment shall be made through VISA/Mastercard/American
                                    Express/Apple Pay/ MADA
                                </li>
                                <li> Payment is not refundable under any circumstances.</li>
                                <li>
                                    {" "}
                                    Submission of{" "}
                                    <span className="text-primary">
                                        <b>STEP 1</b>
                                    </span>{" "}
                                    will not go through until payment is received by{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />
                                </li>
                                <li>
                                    After payment of STEP 1 you will recieve a case number which you
                                    can use in the following steps for chatting and calling the
                                    expert team of
                                    <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    <span className="text-primary">Web Platform</span>
                                </li>
                            </ul>
                            <h6 className="text-primary">Submission</h6>
                            <ul>
                                <li>
                                    {" "}
                                    <b>Examination of APPLICATION:</b> After submitting the
                                    APPLICATION, your application will go to{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} /> legal
                                    team. They will examine your request
                                </li>
                                <li>
                                    {" "}
                                    <b>Time of Examination:</b> It will take from 1 to 3 working
                                    days for our team to issue the result of{" "}
                                    <span className="text-primary">
                                        <b>STEP 1</b>
                                    </span>
                                    .
                                </li>
                                <li>
                                    {" "}
                                    <b>Failure of APPLICATION:</b> If for any reason your
                                    APPLICATION is not acceptable, then you will receive notice of
                                    APPLICATION FAILURE together with the reason for failure and our
                                    advice on how to avoid this failure if you file anew
                                    application.
                                </li>
                                <li>
                                    {" "}
                                    <b>Success of APPLICATION:</b> if the APPLICATION is accepted,
                                    then you will be forwarded to{" "}
                                    <span className="text-primary">
                                        <b>STEP 2</b>
                                    </span>
                                    .
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex justify-content-evenly align-items-center">
                            <CustomButton
                                color="primary"
                                title="Continue"
                                className="bv-btn-sm mb-3"
                                onClick={() => {
                                    setIsPaymentCardSelected(true);
                                    setIsModalOpen(false);
                                }}
                            />
                            <CustomButton
                                color=""
                                title="Cancel"
                                className="bv-btn-sm mb-3 border"
                                onClick={() => setIsModalOpen(false)}
                            />
                        </div>
                    </div>
                }
            />
            {/* Payment Card Modal */}

            <PaymentModal
                isOpen={isPaymentCardSelected}
                amount={systemFee}
                selectedCard={selectedCard}
                onCardSelect={handleCardSelect}
                isFillingLoader={isFillingLoader}
                onContinue={tradeFillingHandler}
                onCancel={() => setIsPaymentCardSelected(false)}
            />

            <CustomModal
                isOpen={isPaymentMethodModal}
                size={"md"}
                data={
                    <div className="p-4 payment-mathod-modal">
                        <div className="d-flex align-items-center">
                            <button
                                className="border-0  p-0  bg-white "
                                onClick={() => {
                                    setIsPaymentCardSelected(true);
                                    setIsPaymentMethodModal(false);
                                }}
                            >
                                <LeftArrowIcon />
                            </button>

                            <h2 className="m-0 px-2">Payment Method</h2>
                        </div>

                        <h3 className="text-center">
                            Amount: <strong>$ {systemFee}</strong>
                        </h3>

                        <div>
                            <TrademarkPaymentModal
                                paymentId={paymentId}
                                selectedCard={selectedCard}
                            />
                        </div>
                    </div>
                }
            />
            <ConfirmationModal
                isOpen={isTrademarkCancelled}
                content={"Are you sure want to cancel the process?"}
                btn_content={"Yes"}
                onYes={() => {
                    history.push(CUSTOMER_ROUTES.TRADEMARK);
                }}
                onNo={handleCancel}
            />
        </React.Fragment>
    );
};

export default TrademarkSubmissionForm;
