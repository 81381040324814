import PropTypes from "prop-types";
import React from "react";
import AuthCode from "react-auth-code-input";

import "./CodeInput.scss";

const CodeInput = (props) => {
    const {
        className,
        characters = 4,
        onChange = () => null,
        isPassword = false,
        disabled = false
    } = props;

    return (
        <div className={`bv-auth-code ${className}`}>
            <AuthCode
                // password
                allowedCharacters="^[0-9]"
                characters={characters}
                className="form-control form-control-lg text-center"
                inputClassName="forget-vc-input"
                // inputStyle={{
                //     width: "4rem",
                //     height: "2.5rem",
                //     padding: ".5rem 1rem",
                //     borderRadius: "5px",
                //     fontSize: "1.01562rem",
                //     textAlign: "center",
                //     marginRight: "16px",
                //     borderWidth: "1px",
                //     borderStyle: "solid",
                //     textTransform: "uppercase",
                //     borderColor: "#704DE7",
                //     backgroundColor: "#FFFFFF"

                // }}
                onChange={onChange}
            />
        </div>
    );
};

CodeInput.propTypes = {
    className: PropTypes.any,
    characters: PropTypes.any,
    onChange: PropTypes.any,
    isPassword: PropTypes.bool,
    disabled: PropTypes.bool,
    allowedCharacters: PropTypes.any
};

export default CodeInput;
