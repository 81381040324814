import { TextInput } from "components/Input";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Col, Form, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import { getMe } from "store/actions";
import toast from "react-hot-toast";
import PhoneInput, {
    isValidPhoneNumber,
    parsePhoneNumber,
    getCountryCallingCode
} from "react-phone-number-input";
import { updateCustomer } from "../../../api/api.service";
import "./ProfileSettingForm.scss";
import { SuccessModalCustom } from "components/SuccessModalCustom";
import { CustomButton } from "components/CustomButton";
import { useDispatch } from "react-redux";

const ProfileSettingForm = (props) => {
    const { userData } = { ...props };
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [resetSuccessModal, setResetSuccessModal] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: userData.name,
            email: userData.email,
            phoneNum: `${userData?.countryCode}${userData?.phone}`
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Enter your full name"),
            email: Yup.string()
                .email("Enter a valid email address")
                .required("Enter email address")
                .min(6, "Too short email!")
                .max(50, "Too long email!")
                .matches(
                    /([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/,
                    "Enter valid email address"
                )
        }),
        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    const handleFormSubmit = async (data) => {
        try {
            // setResetSuccessModal(true);
            const newData = { ...data };
            setIsLoading(true);
            const res = await updateCustomer(userData?.id, newData);

            if (res?.message === "Updated") {
                toast.success("Profile Updated successfully!");
            }
            if (res) {
                await dispatch(getMe());
            }
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };
    const modalCloseHandler = () => {
        setResetSuccessModal(false);
    };
    return (
        <React.Fragment>
            <Form onSubmit={formik.handleSubmit} className="profile-setting-form">
                <FormGroup row>
                    <Col sm={2}>
                        <Label for="exampleEmail">Full Name</Label>
                    </Col>

                    <Col sm={10}>
                        <TextInput
                            placeholder="John Smith"
                            name={"name"}
                            type="text"
                            className="hide-icon"
                            inputGroupClassName={`${
                                formik.touched.name && formik.errors.name && "border-danger"
                            }`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            autoFocus
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <span className="error">{formik.errors.name}</span>
                        ) : null}
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={2}>
                        <Label for="exampleEmail">Email</Label>
                    </Col>

                    <Col sm={10}>
                        <TextInput
                            placeholder="John@gmail.com"
                            name={"email"}
                            type="text"
                            className="hide-icon"
                            inputGroupClassName={`${
                                formik.touched.email && formik.errors.email && "border-danger"
                            }`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            autoFocus
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <span className="error">{formik.errors.email}</span>
                        ) : null}
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={2}>
                        <Label for="exampleEmail">Phone</Label>
                    </Col>

                    <Col sm={10}>
                        <PhoneInput
                            defaultCountry={"SA"}
                            countryOptionsOrder={["SA", "AE", "OM", "BH", "KW", "QA"]}
                            onChange={(values) => formik.setFieldValue("phoneNum", values)}
                            onBlur={() => {
                                formik.setFieldTouched("phoneNum");
                            }}
                            value={formik.values.phoneNum}
                            name="phoneNum"
                            placeholder="Phone Number"
                            className=""
                        />
                    </Col>
                </FormGroup>

                <FormGroup>
                    <Col className="text-end">
                        {/* <button type="submit" >Save Changes</button> */}
                        <CustomButton
                            loading={isLoading}
                            disabled={isLoading}
                            type="submit"
                            color="primary"
                            title="Save Changes"
                            className="login-btn-typo mb-5 w-50"
                        />
                    </Col>
                </FormGroup>
            </Form>
            <SuccessModalCustom
                btnTitle="Done"
                mainContent="Profile settings have been updated successfully"
                modalActivator={resetSuccessModal}
                modalCloseHandler={modalCloseHandler}
            />
        </React.Fragment>
    );
};

export default ProfileSettingForm;
