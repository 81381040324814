import React from "react";
import { Switch, BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";

import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import CustomerLayout from "./components/CustomerLayout/";

import "./assets/scss/theme/theme1/theme.scss";

import { APP_ROUTES, CUSTOMER_ROUTES } from "helpers/routeHelpers";
import { withTitle } from "hoc";

import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/Logout";
import ForgetPassword from "pages/Authentication/ForgetPassword";
import ForgetVerifyCode from "pages/Authentication/ForgetVerifyCode";
import ResetPassword from "pages/Authentication/ResetPassword";

import CustomerLogin from "pages/Authentication/Customer/Login";
import CustomerForgetPassword from "pages/Authentication/Customer/ForgetPassword";
import CustomerResetPasswordView from "pages/Authentication/Customer/ResetPassword";
import CustomerForgetVerifyCode from "pages/Authentication/Customer/ForgetVerifyCode";
import CustomerRegister from "pages/Authentication/Customer/Register";

import { PageTitles } from "./constants";
import { DefaultLayout } from "./containers";
import RegisterVerfify from "pages/Authentication/Customer/RegisterVerifyCode";

const App = () => {

    const layout = useSelector((state) => state.Layout);

    function getLayout() {
        let layoutCls = CustomerLayout;
        switch (layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    }

    const Layout = getLayout();

    const LoginWithTitle = withTitle({
        component: Login,
        title: PageTitles.LOGIN
    });
    const ForgetPasswordWithTitle = withTitle({
        component: ForgetPassword,
        title: PageTitles.FORGET_PASSWORD
    });
    const LogoutWithTitle = withTitle({
        component: Logout,
        title: PageTitles.LOGOUT
    });
    const ResetPasswordWithTitle = withTitle({
        component: ResetPassword,
        title: PageTitles.RESET_PASSWORD
    });
    const OTPVerifyCodeWithTitle = withTitle({
        component: ForgetVerifyCode,
        title: PageTitles.OTP_VERIFY_CODE
    });


    // customer routes

    const CustomerLoginWithTitle = withTitle({
        component: CustomerLogin,
        title: PageTitles.LOGIN
    });

    const CustomerForgetPasswordWithTitle = withTitle({
        component: CustomerForgetPassword,
        title: PageTitles.FORGET_PASSWORD
    });

    const CustomerResetPasswordWithTitle = withTitle({
        component: CustomerResetPasswordView,
        title: PageTitles.RESET_PASSWORD
    });

    const CustomerForgetVerifyCodeWithTitle = withTitle({
        component: CustomerForgetVerifyCode,
        title: PageTitles.OTP_VERIFY_CODE
    });

    const CustomerRegisterWithTitle = withTitle({
        component: CustomerRegister,
        title: PageTitles.REGISTER
    });

    const CustomerRegisterVerifyWithTitle = withTitle({
        component: RegisterVerfify,
        title: PageTitles.REGISTER
    });

    return (
        <React.Fragment>
            <Router>
                <Switch>
                    <Route
                        exact
                        path={CUSTOMER_ROUTES.REGISTER}
                        component={CustomerRegisterWithTitle}
                        name={PageTitles.REGISTER}
                    />
                    <Route
                        exact
                        path={CUSTOMER_ROUTES.REGISTER_VERIFY}
                        component={CustomerRegisterVerifyWithTitle}
                        name={PageTitles.REGISTER_VERIFY}
                    />
                    <Route
                        exact
                        path={CUSTOMER_ROUTES.LOGIN}
                        component={CustomerLoginWithTitle}
                        name={PageTitles.LOGIN}
                    />
                    <Route
                        exact
                        path={CUSTOMER_ROUTES.FORGET_PASSWORD}
                        component={CustomerForgetPasswordWithTitle}
                        name={PageTitles.FORGET_PASSWORD}
                    />
                    <Route
                        exact
                        path={CUSTOMER_ROUTES.OTP_VERIFY_CODE}
                        component={CustomerForgetVerifyCodeWithTitle}
                        name={PageTitles.OTP_VERIFY_CODE}
                    />
                    <Route
                        exact
                        path={CUSTOMER_ROUTES.RESET_PASSWORD}
                        component={CustomerResetPasswordWithTitle}
                        name={PageTitles.RESET_PASSWORD}
                    />

                    {/* admin routes */}
                    <Route
                        exact
                        path={APP_ROUTES.LOGOUT}
                        component={LogoutWithTitle}
                        name={PageTitles.LOGOUT}
                    />
                    <Route
                        exact
                        path={APP_ROUTES.LOGIN}
                        component={LoginWithTitle}
                        name={PageTitles.LOGIN}
                    />
                    <Route
                        exact
                        path={APP_ROUTES.FORGET_PASSWORD}
                        component={ForgetPasswordWithTitle}
                        name={PageTitles.FORGET_PASSWORD}
                    />
                    <Route
                        exact
                        path={APP_ROUTES.OTP_VERIFY_CODE}
                        component={OTPVerifyCodeWithTitle}
                        name={PageTitles.OTP_VERIFY_CODE}
                    />
                    <Route
                        exact
                        path={APP_ROUTES.RESET_PASSWORD}
                        component={ResetPasswordWithTitle}
                        name={PageTitles.RESET_PASSWORD}
                    />
                    <Route
                        path="/"
                        render={(props) => <DefaultLayout {...props} Layout={Layout} />}
                    />
                    <Redirect to={APP_ROUTES.LOGIN} />
                </Switch>
            </Router>

            <Toaster
                position="top-right"
                reverseOrder={false}
                toastOptions={{
                    className: "",
                    duration: 3000,
                    success: {
                        duration: 3000
                    }
                }}
            />
        </React.Fragment>
    );
};

export default App;
