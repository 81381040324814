import React, { useState } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import PhoneInput, {
    isValidPhoneNumber,
    parsePhoneNumber,
    getCountryCallingCode
} from "react-phone-number-input";
import AuthWrapper from "./AuthWrapper";
import { METATAGS } from "../../../constants";
import { TextInput } from "../../../components/Input";
import { CustomButton } from "../../../components/CustomButton";
import { CUSTOMER_ROUTES, getRoute } from "../../../helpers/routeHelpers";
import { login } from "../../../store/actions";
import toast from "react-hot-toast";
import passIcon from "../../../assets/images/aiAPaiT/lock-icon.png";
import showPassIcon from "../../../assets/images/aiAPaiT/show-lock-icon.svg";
import "./AuthStyle.scss";
import Logo from "../../../assets/images/customer/svg/Logo";
import PasswordLockIcon from "../../../assets/images/customer/svg/PasswordLockIcon";
import { ResetPassword } from "api/api.service";

const CustomerLogin = (props) => {
    let { tokenId } = props;
    const history = useHistory();
    const { state } = useLocation();

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [passwordFieldType, setPasswordFieldType] = useState(false);
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const passwordFieldChangeHandler = () => {
        setPasswordFieldType(!passwordFieldType);
    };

    const formik = useFormik({
        initialValues: {
            new_password: "",
            confirm_password: ""
        },
        validationSchema: Yup.object({
            new_password: Yup.string()
                .required("Please Enter Your New Password")
                .min(8, "Password should be minimum 8 characters long")
                .max(25, "Password should be between 8 to 25 characters"),
            // .test(
            //     "regex",
            //     "Password must contain characters, special characters, and numbers",
            //     (val) => {
            //         let regExp = new RegExp(
            //             "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])|(?=.*[A-Z]).{8,}$"
            //         );

            //         // return regExp.test(val);
            //         return true;
            //     }
            // ),
            confirm_password: Yup.string()
                .oneOf([Yup.ref("new_password")], "Please make sure your passwords match")
                .required("Please Re-enter Your Password")
        }),
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = async (values) => {
        let { confirm_password } = values;
        try {
            setIsLoading(true);
            let body = {
                token: state?.tokenId,
                password: confirm_password
            };
            let res = await ResetPassword(body);
            toast.success("Password has been reset successfully !");
            history.push(getRoute(CUSTOMER_ROUTES?.LOGIN));
        } finally {
            setIsLoading(false);
        }
    };

    // if (isAuthenticated) {
    //     return <Redirect to="/charter" />;
    // }

    return (
        <React.Fragment>
            <MetaTags>
                <title>{METATAGS.LOGIN}</title>
            </MetaTags>
            <AuthWrapper
                leftContent={
                    <div style={{ backgroundColor: "#704DE7" }} className="customer-login ">
                        <Logo />
                    </div>
                }
                data={
                    <div className="customer-login-form">
                        <div className="title">
                            <h1 className="">{"Create New Password"}</h1>
                            <p className="mb-4 ms-0 " style={{}}>
                                Keep your password safe
                            </p>
                        </div>

                        <form className="" onSubmit={formik.handleSubmit}>
                            <div className="mb-4">
                                <TextInput
                                    preAppendIconFile={<PasswordLockIcon />}
                                    placeholder="New Password"
                                    type={!passwordFieldType ? "password" : "text"}
                                    className="hide-icon"
                                    inputGroupClassName={`${
                                        formik.touched.new_password &&
                                        formik.errors.new_password &&
                                        "border-danger"
                                    }`}
                                    name="new_password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.new_password}
                                    iconFile={!passwordFieldType ? passIcon : showPassIcon}
                                    appendIcon={true}
                                    iconClickHandler={passwordFieldChangeHandler}
                                />
                                {formik.touched.new_password && formik.errors.new_password ? (
                                    <span className="error">{formik.errors.new_password}</span>
                                ) : null}
                            </div>

                            <div className="mb-4">
                                <TextInput
                                    preAppendIconFile={<PasswordLockIcon />}
                                    placeholder="Confirm Password"
                                    type={!passwordFieldType ? "password" : "text"}
                                    className="hide-icon"
                                    inputGroupClassName={`${
                                        formik.touched.confirm_password &&
                                        formik.errors.confirm_password &&
                                        "border-danger"
                                    }`}
                                    name="confirm_password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirm_password}
                                    iconFile={!passwordFieldType ? passIcon : showPassIcon}
                                    appendIcon={true}
                                    iconClickHandler={passwordFieldChangeHandler}
                                />
                                {formik.touched.confirm_password &&
                                formik.errors.confirm_password ? (
                                    <span className="error">{formik.errors.confirm_password}</span>
                                ) : null}
                            </div>

                            <CustomButton
                                loading={isLoading}
                                type="submit"
                                color="primary"
                                title="Reset Password"
                                className="w-100 login-btn-typo"
                            />
                        </form>
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default withRouter(CustomerLogin);

CustomerLogin.propTypes = {
    history: PropTypes.object
};
