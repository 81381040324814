import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Input,
    Row,
    Col,
    Form,
    FormGroup,
    Label
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { HeaderWithButton } from "components/HeaderWithButton";
import { CustomButton } from "components/CustomButton";
import { APP_ROUTES } from "helpers/routeHelpers";
import { TextInput } from "components/Input";
import { SuccessModalCustom } from "components/SuccessModalCustom";
// import { METATAGS } from "../../constants";
import {
    GetNotification,
    getFirstStepFee,
    updateFirstStepFee,
    updateNotificationStatus
} from "api/api.service";
import "./SettingsTabs.scss";
import ProfileSettingForm from "./ProfileSettingForm";
import Card from "../common/Card";
import { getMe, updateNotificationCount } from "store/actions";
import toast from "react-hot-toast";
import DeleteUser from "./DeleteUser";

const SettingsTabs = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    let userData = useSelector((store) => store?.userAuth?.user);
    const [activeTab, setActiveTab] = useState("1");
    const [allowNotification, setAllowNotification] = useState(
        userData?.settings?.notificationsEnabled
    );

    const [isLoading, setIsLoading] = useState(false);
    const [totalNotifications, setTotalNotifications] = useState();
    const [page, setPage] = useState(1);

    const [notificationMessages, setNotificationMessages] = useState([]);

    // tab click handling

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const notifications = async () => {
        try {
            setIsLoading(true);
            let res = await GetNotification({ page: page });
            let notificationsMerged =
                page === 1 ? res?.data : [...notificationMessages, ...res?.data];
            setNotificationMessages(notificationsMerged);
            setTotalNotifications(res?.total);
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        notifications();
    }, [page]);

    useEffect(async () => {
        await dispatch(updateNotificationCount());
    }, [allowNotification]);

    const handleNotification = async () => {
        setAllowNotification(!allowNotification);

        try {
            const res = await updateNotificationStatus(allowNotification);
            if (res?.data === true) {
                await dispatch(getMe());
                toast.success("Notifications settings updated successfully!");
            }
        } finally {
        }
    };
    const handlePageClick = () => {
        setPage((prevPage) => prevPage + 1);
    };

    return (
        <React.Fragment>
            {/* <ProfileSettingForm userData={userData} /> */}
            <Nav tabs className="customer-tabLink">
                <NavItem>
                    <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={(e) => toggle("1")}
                    >
                        My Profile
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={activeTab === "2" ? "active " : ""}
                        onClick={(e) => toggle("2")}
                    >
                        Delete Account
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="py-4 px-2">
                    <ProfileSettingForm userData={userData} />
                </TabPane>
                <TabPane tabId="2" className="py-4 px-2">
                    <DeleteUser />
                    {/* <Row>
                        <Col className="d-flex justify-content-end  py-3 px-3 ">
                            <Form>
                                <FormGroup switch>
                                    <Label for="notification" check>
                                        Notifications
                                    </Label>
                                    <Input
                                        type="switch"
                                        className="notification-status"
                                        name="notification"
                                        checked={allowNotification}
                                        // onChange={handleNotification}
                                        onClick={() => {
                                            handleNotification();
                                        }}
                                    />
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row> */}

                    {/* {notificationMessages.length > 0 ? (
                        notificationMessages.map((item, idx) => {
                            return <Card key={idx} data={item} />;
                        })
                    ) : (
                        <div className=" my-5 d-flex justify-content-center align-items-center">
                            <h3 className="text-center">No Item Found at the moment</h3>
                        </div>
                    )}
                    {notificationMessages.length < totalNotifications ? (
                        <div className="py-2 text-center ">
                            <CustomButton
                                color="primary"
                                title="See More"
                                className="login-btn-typo mb-5"
                                onClick={handlePageClick}
                            />
                        </div>
                    ) : null} */}
                </TabPane>
            </TabContent>
        </React.Fragment>
    );
};

export default SettingsTabs;
