import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import "./TrademarkCard.scss";
import SuccessIcon from "assets/images/customer/svg/SuccessIcon";
import WaitingIcon from "assets/images/customer/svg/WaitingIcon";
import TrademarkStatusModal from "./TrademarkStatusModal";
import { CUSTOMER_ROUTES } from "helpers/routeHelpers";
import { zoneFormatToDDMMYY } from "utils/dateFormatUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TrademarkCard = (props) => {
    const { data } = {
        ...props
    };
    const [ismodalOpen, setIsmodalOpen] = useState(false);

    const history = useHistory();
    switch (data.trademarkStep) {
        case "FILING":
            data.trademarkStep = "Document Submission";
            data.stepNumber = "01";
            break;
        case "TMCRDOWNLOAD":
            data.trademarkStep = "TMCR";
            data.stepNumber = "05";
            break;
        case "POAAPPLICATION":
            data.trademarkStep = "E Signature";
            data.stepNumber = "02";
            break;
        case "PUBLICATION":
            data.trademarkStep = "PUBLICATION";
            data.stepNumber = "03";
            break;
        case "REGISTRATION":
            data.trademarkStep = "REGISTRATION";
            data.stepNumber = "04";
            break;
    }
    const modalData = {
        title:
            data?.trademarkStepsStatus == "PendingAdmin"
                ? "Waiting For Response"
                : data?.trademarkStepsStatus == "PendingCustomer"
                ? "Request Successful"
                : data?.trademarkStepsStatus == "Rejected"
                ? "Case Rejected"
                : null,
        content:
            data?.trademarkStepsStatus == "PendingAdmin"
                ? "The case is in progress and will be reviewed soon. We will notify you of the next steps."
                : data?.trademarkStepsStatus == "PendingCustomer"
                ? `Your request has successfully reached Step ${data?.stepNumber} of your trademark registration with `
                : data?.trademarkStepsStatus == "Rejected" && Boolean(data?.reason)
                ? `${data?.reason}`
                : "Case has been rejected because the user has declined to sign the document",
        icon:
            data.trademarkStepsStatus == "PendingAdmin" ? (
                <WaitingIcon />
            ) : data.trademarkStepsStatus == "PendingCustomer" ? (
                <SuccessIcon />
            ) : null,
        redirect: `${CUSTOMER_ROUTES.ADD_TRADEMARK}/${data?.id}`,
        status: data.trademarkStepsStatus
    };
    const handleOpenModal = () => {
        if (
            data.trademarkStepsStatus === "Dispatched" ||
            data.trademarkStepsStatus === "Completed"
        ) {
            history.push(`${CUSTOMER_ROUTES.ADD_TRADEMARK}/${data?.id}`);
        } else {
            setIsmodalOpen(true);
        }
    };
    const handleClose = () => {
        setIsmodalOpen(false);
    };
    return (
        <React.Fragment>
            <div className="trademark-card">
                <button onClick={handleOpenModal}>
                    <Row>
                        <Col md={6} lg={6} sm={12}>
                            <div
                                className={`${
                                    data.trademarkStatus == "FileDownloaded"
                                        ? "Completed"
                                        : data.trademarkStatus
                                } status`}
                            >
                                <span></span>
                                {data.trademarkStatus == "FileDownloaded"
                                    ? "File Downloaded"
                                    : data.trademarkStatus}
                            </div>
                        </Col>
                        <Col md={6} lg={6} sm={12} className="text-end">
                            <div className="date">
                                <span>Created on : </span>
                                <span className="date-value">
                                    {zoneFormatToDDMMYY(data?.createdAt)}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="title">
                                {/* <span>{data.serial_number}. </span> */}
                                <p> {data.id + ". " + data.caseTitle}</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="process-detail">
                        <Row>
                            <Col md={7}>
                                <div className="process-stage">
                                    <span>Process:</span>
                                    <div className="process-stage-value">
                                        <span>{data.stepNumber}</span>
                                        {data.trademarkStep}
                                    </div>
                                </div>
                            </Col>
                            <Col md={5} className="text-end">
                                <div className="process-status">
                                    Status:
                                    <span
                                        className={
                                            data.trademarkStepsStatus == "PendingAdmin"
                                                ? "Awaiting"
                                                : data.trademarkStepsStatus == "PendingCustomer"
                                                ? "Pending"
                                                : data.trademarkStepsStatus == "Rejected"
                                                ? "Closed"
                                                : "FileDownload"
                                        }
                                    >
                                        {data.trademarkStepsStatus == "PendingAdmin"
                                            ? "Awaiting"
                                            : data.trademarkStepsStatus == "PendingCustomer"
                                            ? "Pending"
                                            : data.trademarkStepsStatus == "Rejected"
                                            ? "Rejected"
                                            : "File Downloaded"}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </button>
            </div>
            <TrademarkStatusModal
                data={modalData}
                ismodalOpen={ismodalOpen}
                handleClose={handleClose}
            />
        </React.Fragment>
    );
};

export default TrademarkCard;
