import React, { useState } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, useHistory, Redirect, useLocation } from "react-router-dom";

import AuthWrapper from "./AuthWrapper";
import { CustomModal } from "../../../components/CustomModal";
import { CodeInput } from "../../../components/CodeInput";
import { CustomButton } from "../../../components/CustomButton";
import { METATAGS } from "../../../constants";
import { APP_ROUTES, CUSTOMER_ROUTES, getRoute } from "../../../helpers/routeHelpers";
import {
    ForgetPasswordVerification,
    RegisterVerifyCode,
    ResendOtp
} from "../../../api/api.service";
import Logo from "assets/images/customer/svg/Logo";
import { SET_TOKEN } from "store/actions";

const OTP_LENGTH = 4;
const RegisterVerfify = (props) => {
    let { tokenId } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const { state } = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [OTP, setOTP] = useState("");
    const [codeAndToken, setCodeAndToken] = useState({});
    const [isResendCode, setIsResendCode] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);

    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            let body = {
                token: state?.tokenId,
                code: OTP
            };
            // if (res?.token) {
            //     toast.success("OTP has been verified successfully!");
            //     setTimeout(() => {
            //         history.push(CUSTOMER_ROUTES.LOGIN)
            //     }, 1200);
            // }
            await RegisterVerifyCode(body)
                .then(async (res) => {
                    dispatch({ type: SET_TOKEN, token: res.token });
                    toast.success("Verification successful!");

                    setTimeout(() => {
                        history.push(CUSTOMER_ROUTES.LOGIN);
                    }, 1200);

                    return Promise.resolve({});
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1200);
        }
    };

    const handleResendCode = async () => {
        try {
            setIsResendCode(true);
            let body = {
                token: state?.tokenId,
                countryCode: state?.phoneData?.countryCode,
                phone: state?.phoneData?.phone
            };
            let res = await ResendOtp(body);
            toast.success("An OTP has been sent to your phone number and email !");
            history.push({
                pathname: CUSTOMER_ROUTES.OTP_VERIFY_CODE,
                state: { tokenId: res?.token }
            });
        } finally {
            setIsResendCode(false);
            setOTP("");
        }
    };

    // if (!location?.state?.email) {
    //     <Redirect to={APP_ROUTES.DASHBOARD} />;
    // }

    if (isAuthenticated) {
        return <Redirect to={CUSTOMER_ROUTES.TRADEMARK} />;
    }

    return (
        <>
            <MetaTags>
                <title>{METATAGS.REGISTER_VERIFY}</title>
            </MetaTags>
            <AuthWrapper
                leftContent={
                    <div style={{ backgroundColor: "#704DE7" }} className="customer-login ">
                        <Logo />
                    </div>
                }
                data={
                    <div className="customer-login-form">
                        <div className="title">
                            <h1 className="">{"One Time Password"}</h1>
                            <p className="mb-4 ms-0 " style={{}}>
                                Enter code sent to your provided phone number and email
                            </p>
                        </div>

                        <form className="">
                            <div className="mb-4">
                                <CodeInput className="my-4 text-left" onChange={(e) => setOTP(e)} />
                            </div>

                            <CustomButton
                                loading={isLoading}
                                type="submit"
                                onClick={handleSubmit}
                                color="primary"
                                title="Verify"
                                className="w-100 fw-bold login-btn-typo"
                            />
                        </form>
                        <div
                            className="d-flex mt-4 align-items-center justify-content-center"
                            onClick={handleResendCode}
                        >
                            <a className="resend-otp-typo">
                                {isResendCode ? "Sending..." : "Resend OTP"}
                            </a>
                        </div>
                    </div>
                }
            />
            <CustomModal
                isOpen={showOtpModal}
                data={
                    <div className="text-center">
                        <CustomButton
                            color="primary"
                            title="Continue"
                            className="bv-btn-sm"
                            onClick={() => {
                                history.push(CUSTOMER_ROUTES.LOGIN);
                            }}
                        />
                    </div>
                }
            />
        </>
    );
};

RegisterVerfify.propTypes = {
    history: PropTypes.object
};

export default RegisterVerfify;
