import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { withRouter, useHistory, Redirect, useLocation } from "react-router-dom";

import AuthWrapper from "./AuthWrapper";
import { CustomModal } from "../../../components/CustomModal";
import { CodeInput } from "../../../components/CodeInput";
import { CustomButton } from "../../../components/CustomButton";
import { METATAGS } from "../../../constants";
import { APP_ROUTES, CUSTOMER_ROUTES, getRoute } from "../../../helpers/routeHelpers";
import { ForgetPasswordVerification, ResendOtp } from "../../../api/api.service";
import Logo from "assets/images/customer/svg/Logo";

const OTP_LENGTH = 4;
const RESEND_INTERVAL = 60; // 60 seconds

const CustomerForgetVerifyCode = (props) => {
    let { tokenId } = props;

    const history = useHistory();
    const { state } = useLocation();

    let isAuthenticated = useSelector((state) => state?.userAuth?.isAuthenticated);

    const [isLoading, setIsLoading] = useState(false);
    const [OTP, setOTP] = useState("");
    const [codeAndToken, setCodeAndToken] = useState({});
    const [isResendCode, setIsResendCode] = useState(true);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [resetPassword, setResetPassword] = useState(null);
    const [timer, setTimer] = useState(RESEND_INTERVAL);

    useEffect(() => {
        let interval;
        const savedTimer = localStorage.getItem("otpTimer");
        const lastTime = localStorage.getItem("lastTime");
        const timeElapsed = Math.floor((Date.now() - lastTime) / 1000);

        if (savedTimer && timeElapsed < RESEND_INTERVAL) {
            setTimer(Math.max(0, savedTimer - timeElapsed));
        }
        if (isResendCode) {
            interval = setInterval(() => {
                setTimer((prev) => {
                    const updatedTime = prev - 1;
                    localStorage.setItem("otpTimer", updatedTime);
                    localStorage.setItem("lastTime", Date.now());

                    if (updatedTime <= 0) {
                        clearInterval(interval);
                        setIsResendCode(false);
                        localStorage.removeItem("otpTimer");
                        localStorage.removeItem("lastTime");
                        return RESEND_INTERVAL;
                    }
                    return updatedTime;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isResendCode]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            let body = {
                token: state?.tokenId,
                code: OTP
            };
            let res = await ForgetPasswordVerification(body);
            toast.success("OTP has been verified successfully!");
            setTimeout(() => {
                setResetPassword(true);
            }, 1200);
            let { token } = res;
            setCodeAndToken({ tokenUId: token });
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1200);
        }
    };

    const handleResendCode = async () => {
        try {
            setIsResendCode(true); // Start timer
            let body = {
                token: state?.tokenId,
                countryCode: state?.phoneData?.countryCode,
                phone: state?.phoneData?.phone
            };
            let res = await ResendOtp(body);
            toast.success("An OTP has been sent to your phone number and email !");
            history.push({
                pathname: CUSTOMER_ROUTES.OTP_VERIFY_CODE,
                state: { tokenId: res?.token }
            });
        } catch (err) {
        } finally {
            setOTP(""); // Clear OTP input
        }
    };

    if (resetPassword) {
        history.push({
            pathname: CUSTOMER_ROUTES?.RESET_PASSWORD,
            state: { tokenId: codeAndToken?.tokenUId }
        });
    }

    // if (!location?.state?.email) {
    //     <Redirect to={APP_ROUTES.DASHBOARD} />;
    // }

    // if (isAuthenticated) {
    //     return <Redirect to={APP_ROUTES.DASHBOARD} />;
    // }

    return (
        <>
            <MetaTags>
                <title>{METATAGS.FORGOT_VERIFY_CODE}</title>
            </MetaTags>
            <AuthWrapper
                leftContent={
                    <div style={{ backgroundColor: "#704DE7" }} className="customer-login ">
                        <Logo />
                    </div>
                }
                data={
                    <div className="customer-login-form">
                        <div className="title">
                            <h1 className="">{"One Time Password"}</h1>
                            <p className="mb-4 ms-0 " style={{}}>
                                Enter the code sent to your provided Phone Number and Email
                            </p>
                        </div>

                        <form className="">
                            <div className="mb-4">
                                <CodeInput className="my-4 text-left" onChange={(e) => setOTP(e)} />
                            </div>

                            <CustomButton
                                disabled={OTP.length !== OTP_LENGTH}
                                loading={isLoading}
                                type="submit"
                                onClick={handleSubmit}
                                color="primary"
                                title="Verify"
                                className="w-100 fw-bold login-btn-typo"
                            />
                        </form>
                        <div
                            className={`d-flex mt-4 align-items-center justify-content-center  ${
                                !isResendCode
                                    ? "cursor-pointer customer-resend-otp-typo"
                                    : "customer-resend-otp-typo"
                            } `}
                            // onClick={handleResendCode}
                            onClick={!isResendCode ? handleResendCode : null}
                        >
                            {/* {isResendCode ? "Sending..." : "Resend OTP"} */}
                            {isResendCode ? `Resend OTP in (${timer} sec)` : "Resend Now"}
                        </div>
                    </div>
                }
            />
            <CustomModal
                isOpen={showOtpModal}
                data={
                    <div className="text-center">
                        <CustomButton
                            color="primary"
                            title="Continue"
                            className="bv-btn-sm"
                            onClick={() => {
                                history.push(
                                    getRoute(CUSTOMER_ROUTES.RESET_PASSWORD, {
                                        tokenId: codeAndToken?.tokenUId
                                    })
                                );
                            }}
                        />
                    </div>
                }
            />
        </>
    );
};

CustomerForgetVerifyCode.propTypes = {
    history: PropTypes.object
};

export default CustomerForgetVerifyCode;
