import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import PhoneInput, {
    isValidPhoneNumber,
    parsePhoneNumber,
    getCountryCallingCode
} from "react-phone-number-input";
import AuthWrapper from "./AuthWrapper";
import { METATAGS } from "../../../constants";
import { TextInput } from "../../../components/Input";
import { CustomButton } from "../../../components/CustomButton";
import { CUSTOMER_ROUTES } from "../../../helpers/routeHelpers";
import { login } from "../../../store/actions";
import toast from "react-hot-toast";
import passIcon from "../../../assets/images/aiAPaiT/lock-icon.png";
import showPassIcon from "../../../assets/images/aiAPaiT/show-lock-icon.svg";
import "./AuthStyle.scss";
import Logo from "../../../assets/images/customer/svg/Logo";
import PasswordLockIcon from "../../../assets/images/customer/svg/PasswordLockIcon";
import { head } from "lodash";
import LoaderIcon from "assets/images/customer/svg/LoaderIcon";
import { CustomModal } from "components/CustomModal";
import shortLogo from "assets/images/customer/short-logo.png";

const CustomerLogin = (props) => {
    const history = useHistory();

    let {} = props;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [passwordFieldType, setPasswordFieldType] = useState(false);

    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const passwordFieldChangeHandler = () => {
        setPasswordFieldType(!passwordFieldType);
    };

    const formik = useFormik({
        initialValues: {
            phoneNum: "",
            password: ""
        },
        validationSchema: Yup.object({
            phoneNum: Yup.string()
                .required("Enter Phone Number")
                .test("phoneNum", "Phone number is invalid", function (val) {
                    return val && isValidPhoneNumber(val);
                }),
            password: Yup.string().required("Enter Password")
        }),
        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    const handleFormSubmit = async (values) => {
        const { phoneNum, password } = values;

        let countryCodeAlpha = parsePhoneNumber(phoneNum).country;
        let countryCode = getCountryCallingCode(countryCodeAlpha);
        countryCode = `+${countryCode}`;
        let phone = phoneNum.split(countryCode)[1];

        try {
            setIsLoading(true);
            let res = await dispatch(
                login({
                    countryCode: countryCode,
                    phone: phone,
                    password: password
                })
            );
            // console.log(res);

            toast.success("Login successfully !");
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    // if (!setIsLoading) {
    //     return <Redirect to="/trademarks" />;
    //     // console.log("set laoding false");
    // }
    if (isAuthenticated) {
        return <Redirect to="/trademarks" />;
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>{METATAGS.LOGIN}</title>
            </MetaTags>

            <AuthWrapper
                leftContent={
                    <div style={{ backgroundColor: "#704DE7" }} className="customer-login ">
                        <Logo />
                    </div>
                }
                data={
                    <div className="customer-login-form">
                        <div className="title">
                            <h1 className="">{"Let’s Sign You In"}</h1>
                            <p className="mb-4 ms-0 " style={{}}>
                                Welcome Back
                            </p>
                        </div>

                        <form className="" onSubmit={formik.handleSubmit}>
                            <div className="mb-4">
                                <PhoneInput
                                    defaultCountry={"SA"}
                                    countryOptionsOrder={["SA", "AE", "OM", "BH", "KW", "QA"]}
                                    onChange={(values) => formik.setFieldValue("phoneNum", values)}
                                    onBlur={() => {
                                        formik.setFieldTouched("phoneNum");
                                    }}
                                    value={formik.values.phoneNum}
                                    name="phoneNum"
                                    placeholder="Phone Number"
                                    className="phone-num-con"
                                />
                                {formik.touched.phoneNum && formik.errors.phoneNum ? (
                                    <span className="error">{formik.errors.phoneNum}</span>
                                ) : null}
                            </div>

                            <div className="mb-2">
                                <TextInput
                                    preAppendIconFile={<PasswordLockIcon />}
                                    placeholder="Password"
                                    type={!passwordFieldType ? "password" : "text"}
                                    className="hide-icon"
                                    inputGroupClassName={`${
                                        formik.touched.password &&
                                        formik.errors.password &&
                                        "border-danger"
                                    }`}
                                    name="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    iconFile={!passwordFieldType ? passIcon : showPassIcon}
                                    appendIcon={true}
                                    iconClickHandler={passwordFieldChangeHandler}
                                />
                                {formik.touched.password && formik.errors.password ? (
                                    <span className="error">{formik.errors.password}</span>
                                ) : null}
                            </div>
                            <div className="d-flex align-items-center justify-content-end ">
                                <Link
                                    className="mb-5 login-forget-pass-typo"
                                    to={CUSTOMER_ROUTES.FORGET_PASSWORD}
                                >
                                    Forgot Password?
                                </Link>
                            </div>
                            <CustomButton
                                loading={isLoading}
                                type="submit"
                                color="primary"
                                title="Login"
                                className="w-100 login-btn-typo"
                            />
                            <div
                                className="d-flex align-items-center justify-content-center py-3"
                                style={{ fontSize: "1rem", color: "#8566E7" }}
                            >
                                <p style={{ fontWeight: "600" }}>New Here? </p>
                                <Link
                                    to={CUSTOMER_ROUTES.REGISTER}
                                    style={{ fontWeight: "600" }}
                                    className="px-1"
                                >
                                    Register Now
                                </Link>
                            </div>
                        </form>
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default withRouter(CustomerLogin);

CustomerLogin.propTypes = {
    history: PropTypes.object
};
