import React, { useEffect, useState } from "react";

import { CardTypes } from "../../../constants";

function TrademarkPaymentModal(props) {
    const { paymentId, selectedCard } = { ...props };

    useEffect(() => {
        const script = document.createElement("script");
        // :TODO: move link to .env
        // let scriptLink = "https://oppwa.com/v1/paymentWidgets.js?checkoutId=" + paymentId;
        let scriptLink = "https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=" + paymentId;
        script.src = scriptLink;
        script.async = true;
        document.body.appendChild(script);
    }, [paymentId]);

    return (
        <>
            <form
                action="/trademarks/trademark-payment"
                class="paymentWidgets"
                data-brands={CardTypes.getDisplayTextKey(selectedCard)}
            ></form>
        </>
    );
}

export default TrademarkPaymentModal;
