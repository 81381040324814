import { Dashboard } from "../pages/Dashboard";
import { CaseManagementDetails, CaseManagementListing } from "../pages/CaseManagement";
import { CustomerManagementDetails, CustomerManagementListing } from "../pages/CustomerManagement";
import { SubAdminListing, SubAdminDetails } from "../pages/Departments";
import { EarningListing, EarningDetails } from "../pages/Earnings";
import { CountriesListing } from "../pages/Countries";
import { ChatsDetails, ChatsListing } from "../pages/Chats";
import { SettingsListing, SettingsDetails, ChangePassword } from "../pages/Settings";

import { APP_ROUTES, CUSTOMER_ROUTES } from "../helpers/routeHelpers";
import { Trademark } from "pages/Customer/Trademark";
import { AddTrademark } from "pages/Customer/Trademark";
import { Settings } from "pages/Customer/Settings";
import { Profile } from "pages/Customer/Profile";
import { Payment, PaymentDetail } from "pages/Customer/Payment";
import Chats from "pages/Customer/Chats/Chats";
import { ChatDetails } from "pages/Customer/Chats";
import TrademarkPayment from "pages/Customer/Trademark/TrademarkPayment";

const authProtectedRoutes = [
    {
        path: APP_ROUTES.DASHBOARD,
        component: Dashboard,
        exact: true
    },

    {
        path: APP_ROUTES.CASE_MANAGEMENT,
        component: CaseManagementListing,
        exact: true
    },
    {
        path: `${APP_ROUTES.CASE_MANAGEMENT_DETAILS}/:id`,
        component: CaseManagementDetails,
        exact: true
    },
    {
        path: APP_ROUTES.CUSTOMER_MANAGEMENT,
        component: CustomerManagementListing,
        exact: true
    },
    {
        path: `${APP_ROUTES.CUSTOMER_MANAGEMENT_DETAILS}/:id`,
        component: CustomerManagementDetails,
        exact: true
    },
    {
        path: APP_ROUTES.SUB_ADMINS,
        component: SubAdminListing,
        exact: true
    },
    {
        path: `${APP_ROUTES.SUB_ADMINS_DETAILS}/:id`,
        component: SubAdminDetails,
        exact: true
    },
    {
        path: APP_ROUTES.EARNINGS,
        component: EarningListing,
        exact: true
    },
    {
        path: `${APP_ROUTES.EARNINGS_DETAILS}/:id`,
        component: EarningDetails,
        exact: true
    },
    {
        path: APP_ROUTES.COUNTRIES,
        component: CountriesListing,
        exact: true
    },
    {
        path: `${APP_ROUTES.CHATS}/:id?`,
        component: ChatsListing,
        exact: true
    },

    {
        path: APP_ROUTES.SETTINGS,
        component: SettingsListing,
        exact: true
    },
    {
        path: `${APP_ROUTES.SETTINGS_DETAILS}`,
        component: SettingsDetails,
        exact: true
    },
    {
        path: `${APP_ROUTES.SETTINGS_CHANGE_PASS}`,
        component: ChangePassword,
        exact: true
    }
];

const CustomerProtectedRoutes = [
    {
        path: CUSTOMER_ROUTES.TRADEMARK,
        component: Trademark,
        exact: true
    },
    {
        // path: CUSTOMER_ROUTES.ADD_TRADEMARK,
        path: `${CUSTOMER_ROUTES.ADD_TRADEMARK}/:id?`,
        component: AddTrademark,
        exact: false
    },
    {
        path: CUSTOMER_ROUTES.SETTINGS,
        component: Settings,
        exact: true
    },
    {
        path: CUSTOMER_ROUTES.PROFILE,
        component: Profile,
        exact: true
    },
    {
        path: CUSTOMER_ROUTES.PAYMENT,
        component: Payment,
        exact: true
    },
    {
        path: `${CUSTOMER_ROUTES.PAYMENT_DETAILS}/:id?`,
        component: PaymentDetail,
        exact: false
    },
    {
        path: CUSTOMER_ROUTES.CHATS,
        component: Chats,
        exact: true
    },
    {
        path: `${CUSTOMER_ROUTES.CHAT_DETAILS}/:id?`,
        component: ChatDetails,
        exact: true
    },
    {
        path: CUSTOMER_ROUTES.TRADEMARK_PAYMENT,
        component: TrademarkPayment,
        exact: true
    },
];

export { authProtectedRoutes, CustomerProtectedRoutes };
